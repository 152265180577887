/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Nav toggle
        $('.js-nav-toggle').on('click', function() {
          $(this).toggleClass('open');
          $('.js-nav-pri').slideToggle();
          return false;
        });

        $('.js-disabled a').on('click', function() {
          return false;
        });

        if ($('.js-question').length) {
          $('.js-question').on('click', function() {
            $(this).toggleClass('active').next('.js-answer').slideToggle();
            return false;
          });
        }

        if ($('.js-m-book').length) {
          $('.js-m-book').on('click', function() {
            $('body').toggleClass('no-scroll');
            $(this).toggleClass('active');
            $('.js-menu-booking').slideToggle();
            return false;
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // Get current lang
        var lang = $('html').attr('lang');

        if (lang === 'fr-CA') {
          lang = 'fr-ca';
        } else {
          lang = 'en';
        }

        // current date
        var currentDate = moment().format('YYYY-MM-DD');

        if ($('.js-datepicker')) {
          // http://eonasdan.github.io/bootstrap-datetimepicker/
          // if ($(window).width() > 767 && !(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
            $('.js-datepicker input').datetimepicker({
              format: "YYYY-MM-DD",
              locale: lang,
              minDate: currentDate,
              // debug: true,
            });
          // }
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_quote': {
      init: function() {
        // Get current lang
        var lang = $('html').attr('lang');

        if (lang === 'fr-CA') {
          lang = 'fr-ca';
        } else {
          lang = 'en';
        }

        // current date
        var currentDate = moment().format('YYYY-MM-DD');

        if ($('.js-datepicker')) {
          // http://eonasdan.github.io/bootstrap-datetimepicker/
          // if ($(window).width() > 767 && !(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
            $('.js-datepicker input').datetimepicker({
              format: "YYYY-MM-DD",
              locale: lang,
              minDate: currentDate,
              // debug: true,
            });
          // }
        }
      }
    },
    'single_fleet': {
      init: function() {

        // CountUp JS
        var options = {
          useEasing : true, 
          useGrouping : true, 
          separator : '', 
          decimal : '.', 
          prefix : '', 
          suffix : '' 
        };

        var nbStatNumber1 = $('#js-stats-number-1').data('stats-nb');
        var nbStatNumber2 = $('#js-stats-number-2').data('stats-nb');
        var nbStatNumber3 = $('#js-stats-number-3').data('stats-nb');
        var nbStatNumber4 = $('#js-stats-number-4').data('stats-nb');
        var countUp1 = new CountUp('js-stats-number-1', 0, nbStatNumber1, 0, 4, options);
        var countUp2 = new CountUp('js-stats-number-2', 0, nbStatNumber2, 0, 4, options);
        var countUp3 = new CountUp('js-stats-number-3', 0, nbStatNumber3, 0, 4, options);
        var countUp4 = new CountUp('js-stats-number-4', 0, nbStatNumber4, 0, 4, options);

        var statsStarted = false;


        // init controller
        var controller = new ScrollMagic.Controller();

        // .js-image-side
        var imageSideTween = TweenMax.from('.js-image-side', 1, {
          y: '150%',
          ease: Power0.easeNone
        });

        var imageSide = new ScrollMagic.Scene({
          triggerElement: '.js-text',
          triggerHook: 1,
          duration: '80%'
        }).setTween(imageSideTween);

        // .js-image-content
        var imageContentTween = TweenMax.from('.js-image-content', 1, {
          x: '-100%',
          ease: Power0.easeNone
        });

        var imageContent = new ScrollMagic.Scene({
          triggerElement: '.js-text',
          triggerHook: 1,
          duration: '80%'
        }).setTween(imageContentTween);

        // .js-stats start countUpJS
        var stats = new ScrollMagic.Scene({
          triggerElement: '.js-stats',
        }).on('start', function () {
          if (!countUp1.error && statsStarted !== true) {
            countUp1.start();
          }

          if (!countUp2.error && statsStarted !== true) {
            countUp2.start();
          }

          if (!countUp3.error && statsStarted !== true) {
            countUp3.start();
          }

          if (!countUp4.error && statsStarted !== true) {
            countUp4.start();
          }

          statsStarted = true;
        });

        // Call all scenes
        controller.addScene([
          imageSide,
          imageContent,
          stats,
        ]);

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
